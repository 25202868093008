.circle-1 {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url("../images/bg-pattern-light.svg");
  background-size: cover;
  background-position: center;
}

.account-pages {
  align-items: center;
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.text-decoration {
  text-decoration: inherit;
}

.educ-items {
  max-width: 55%;
}

.dt-item-area .educ-items div:first-child {
  white-space: normal;
}

.dt-item-area .educ-items div:first-child label {
  display: inline;
}

.auth-fluid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background: url("../images/bg-auth.jpg") center;
  background-size: cover;
}

.auth-fluid .auth-fluid-form-box {
  max-width: 30%;
  border-radius: 0;
  z-index: 2;
  padding: 3rem 3rem;
  background-color: #fff;
  position: relative;
  width: 100%;
}

.auth-brand {
  margin-bottom: 2rem;
  background: transparent;
  border-radius: unset;
  border: 0px transparent;
}

.auth-fluid .card-title {
  font-size: 1.125rem;
}

.auth-fluid .form-label {
  font-weight: 600;
}

.auth-fluid .auth-fluid-right {
  padding: 6rem 3rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.psv_ready_filter_options label,
.psv_ready_filter_options input {
  display: inline-block !important;
}

.d-ruby {
  display: ruby;
}

.bg-light-red {
  background-color: #ff5733 !important;
}

.file-upload-container {
  position: relative;
  display: inline-block;
}

.csv-errors {
  margin: 0 0 0 10px !important;
  border-radius: 0px !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

@media (max-width: 1300px) {
  .auth-fluid .auth-fluid-form-box {
    max-width: 40%;
  }
}

@media (max-width: 991.98px) {
  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }
  .auth-fluid .auth-fluid-right {
    display: none;
  }
}

@media (min-width: 992px) {
  .auth-brand {
    position: absolute;
    top: 3rem;
  }
}

/* For date picker to align the icon right */
.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 4px;
  top: 2px;
}

.react-datepicker__view-calendar-icon input {
  padding-left: 10px !important;
}
